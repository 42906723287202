body {
    margin: 0;
    font-family: Helvetica, Arial, sans-serif;
}

/* in table cells, vertical alignement to top */
/* in table cells, set padding to 0.5 em */

table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 0.5em;
}

td {
    vertical-align: top;
}

/* Alternate light gray and white rows */
tr:nth-child(even) {
    background-color: #eee;
}




